import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { pluck, isEmpty, isNil } from 'ramda';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { useApp, useCustomerLensCreation, useCustomerLensDefinition } from 'hooks';
import Heading from 'components/Heading';
import Drawer from 'components/Drawer';
import LensPresenter from 'presenters/LensPresenter';
import CustomerLensPresenter from 'presenters/CustomerLensPresenter';
import { isBlank } from 'utils';
import AppRoutes from 'routes/AppRoutes';

import { findBy } from 'utils/storeUtils';

import Info from './components/Info';
import LensCard from './components/LensCard';

import useStyles from './useStyles';

const CustomerLensDefinition = props => {
  const { onCreateCustomerLens, customerLens } = props;

  const { showLoader, hideLoader } = useApp();
  const { customerLensStatuses } = useCustomerLensCreation();

  const classes = useStyles();
  const history = useHistory();

  const previouslySelectedLenses = isBlank(customerLens)
    ? []
    : CustomerLensPresenter.selectedLenses(customerLens).map(({ lens }) => lens);
  const [selectedLenses, setSelectedLens] = useState(previouslySelectedLenses);
  const { loadLenses, otherLenses, customizedLenses, balancedLenses } = useCustomerLensDefinition();
  const [isExtendedDescriptionOpened, setExtendedDescriptionOpenedState] = useState(false);
  const [currentLens, setCurrentLens] = useState({});

  const sortedOtherLenses = LensPresenter.sortedByDisplayOrder(otherLenses);

  const isNoSelectedLenses = isEmpty(selectedLenses);

  useEffect(() => {
    showLoader('loading');
    loadLenses().finally(() => {
      hideLoader();
    });
  }, []);

  const handleLensSelect = lens => {
    const hasElement = selectedLenses.some(i => i.id === lens.id);

    if (hasElement) {
      const filtered = selectedLenses.filter(i => i.id !== lens.id);
      setSelectedLens(filtered);
      return;
    }
    setSelectedLens([...selectedLenses, lens]);
  };

  const handleCustomerLensSubmit = () => {
    showLoader('calibrating');
    const selectedLensIds = pluck('id', selectedLenses);
    const params = {
      selectedLensIds,
    };

    onCreateCustomerLens(params).then(({ payload }) => {
      const {
        customerLens: { id },
      } = payload;
      hideLoader();

      history.push(AppRoutes.customerLensValuesPath(id));
    });
  };

  const handleOpenExtendedDescription = lens => () => {
    setExtendedDescriptionOpenedState(true);
    setCurrentLens(lens);
  };

  const handleCloseExtendedDescription = () => {
    setExtendedDescriptionOpenedState(false);
    setCurrentLens({});
  };

  const handleBack = () => {
    history.push(AppRoutes.rootPath());
  };

  const isCurrentLensSelected = !isNil(findBy('id', LensPresenter.id(currentLens), selectedLenses));

  return (
    <div className={classes.root}>
      <Heading
        intro="What is your focus?"
        title="Define Your Lens"
        description="You may be particularly focused on certain values-based goals for your portfolio or you may want to balance
          across Environmental, Social, and Governance considerations."
        className={classes.heading}
      />
      <div className={classes.selectGroup}>
        <h4 className={classes.selectGroupHeader}>Option 1: Select one or more focuses for your lens </h4>
        <Grid container spacing={5} alignItems="stretch">
          {sortedOtherLenses.map(lens => (
            <Grid item xs={12} sm={6} md={3} key={LensPresenter.id(lens)}>
              <LensCard
                lens={lens}
                onSelect={handleLensSelect}
                selected={selectedLenses}
                disabled={LensPresenter.isOtherLensesDisabled(selectedLenses)}
                onOpenExtendedDescription={handleOpenExtendedDescription}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <div className={classes.selectGroup}>
              <h4 className={classes.selectGroupHeader}>Option 2: Select a balanced lens</h4>
              {balancedLenses.map(lens => (
                <LensCard
                  lens={lens}
                  selected={selectedLenses}
                  onSelect={handleLensSelect}
                  horizontal
                  key={lens.id}
                  disabled={LensPresenter.isBalancedLensesDisabled(selectedLenses)}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.selectGroup}>
              <h4 className={classes.selectGroupHeader}>Option 3: Customize a lens</h4>
              {customizedLenses.map(lens => (
                <LensCard
                  lens={lens}
                  onSelect={handleLensSelect}
                  selected={selectedLenses}
                  horizontal
                  key={lens.id}
                  disabled={LensPresenter.isCustomizedLensesDisabled(selectedLenses)}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.formActions}>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCustomerLensSubmit}
            disabled={customerLensStatuses.isLoading || isNoSelectedLenses}
          >
            Continue
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button size="small" variant="text" onClick={handleBack}>
            Back
          </Button>
        </div>
      </div>
      <Drawer open={isExtendedDescriptionOpened} onClose={handleCloseExtendedDescription}>
        <Info
          lens={currentLens}
          onClose={handleCloseExtendedDescription}
          onSelect={handleLensSelect}
          selected={isCurrentLensSelected}
        />
      </Drawer>
    </div>
  );
};

CustomerLensDefinition.propTypes = {
  customerLens: CustomerLensPresenter.shape().isRequired,
  onCreateCustomerLens: PropTypes.func.isRequired,
};

export default CustomerLensDefinition;
