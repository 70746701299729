import PropTypes from 'prop-types';
import { sortBy } from 'ramda';
import Presenter from 'utils/PropTypesPresenter';

import faith from 'assets/faith.svg';
import fossilFuel from 'assets/fossilFuel.svg';
import gender from 'assets/gender.svg';
import green from 'assets/green.svg';
import balanced from 'assets/balanced.svg';
import custom from 'assets/custom.svg';

import faithSelected from 'assets/faithSelected.svg';
import fossilSelected from 'assets/fossilSelected.svg';
import genderSelected from 'assets/genderSelected.svg';
import greenSelected from 'assets/greenSelected.svg';
import balancedSelected from 'assets/balancedSelected.svg';
import customSelected from 'assets/customSelected.svg';

import faithMonochrome from 'assets/icons/faithMonochrome.svg';
import genderMonochrome from 'assets/icons/genderMonochrome.svg';
import greenMonochrome from 'assets/icons/greenMonochrome.svg';
import fossilMonochrome from 'assets/icons/fossilMonochrome.svg';
import balancedMonochrome from 'assets/icons/balancedMonochrome.svg';
import customizedMonochrome from 'assets/icons/customizedMonochrome.svg';

const icons = {
  'Gender Equity': {
    normal: gender,
    selected: genderSelected,
  },
  Faith: {
    normal: faith,
    selected: faithSelected,
  },
  Sustainability: {
    normal: green,
    selected: greenSelected,
  },
  'Ex-Fossil Fuel': {
    normal: fossilFuel,
    selected: fossilSelected,
  },
  'Balanced ESG': {
    normal: balanced,
    selected: balancedSelected,
  },
  Custom: {
    normal: custom,
    selected: customSelected,
  },
};

const LENS_DESCRIPTION_ITEMS = {
  'Gender Equity': {
    title: 'Sample of data reviewed',
    items: ['Pay & Benefits', 'Career Development & Training', 'Workforce & Senior Management Composition'],
  },
  Faith: {
    title: 'Sample of data reviewed',
    items: [
      'Environmental Policies (Care for God’s Planet)',
      'Human Capital Practices (Care for God’s People)',
      'Product Compliance (Morals Alignment)',
    ],
  },
  Sustainability: {
    title: 'Sample of data reviewed',
    items: ['Energy Use', 'Total Green House Gas Emissions', 'Environmental Fines & Penalties'],
  },
  'Ex-Fossil Fuel': {
    title: 'Sample of data reviewed',
    items: ['Energy Mix from Renewables', 'Emissions Reduction Policies', 'Hazardous Waste Production'],
  },
};

const LENS_KINDS = {
  other: 'other',
  balanced: 'balanced',
  customized: 'customized',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    extendedDescription: PropTypes.string,
    imageUrl: PropTypes.string,
    kind: PropTypes.string,
    displayOrder: PropTypes.number,
  },
  {
    icon(lens) {
      const name = this.name(lens);
      const iconName = icons[name];

      return iconName.normal;
    },
    iconSelected(lens) {
      const name = this.name(lens);
      const iconName = icons[name];

      return iconName.selected;
    },
    isOtherLens(lens) {
      return this.kind(lens) === LENS_KINDS.other;
    },
    isBalancedLens(lens) {
      return this.kind(lens) === LENS_KINDS.balanced;
    },
    isCustomizedLens(lens) {
      return this.kind(lens) === LENS_KINDS.customized;
    },
    isOtherLensesDisabled(lenses) {
      return lenses.some(lens => this.isBalancedLens(lens) || this.isCustomizedLens(lens));
    },
    isBalancedLensesDisabled(lenses) {
      return lenses.some(lens => this.isOtherLens(lens) || this.isCustomizedLens(lens));
    },
    isCustomizedLensesDisabled(lenses) {
      return lenses.some(lens => this.isOtherLens(lens) || this.isBalancedLens(lens));
    },
    getDescriptionItems(lens) {
      const name = this.name(lens);

      return LENS_DESCRIPTION_ITEMS[name];
    },
    sortedByDisplayOrder(lenses) {
      const getDisplayOrder = lens => this.displayOrder(lens);

      return sortBy(getDisplayOrder, lenses);
    },
    getIcon(lensName) {
      switch (lensName) {
        case 'Gender Equity':
          return genderMonochrome;
        case 'Faith':
          return faithMonochrome;
        case 'Sustainability':
          return greenMonochrome;
        case 'Ex-Fossil Fuel':
          return fossilMonochrome;
        case 'Balanced ESG':
          return balancedMonochrome;
        case 'Custom':
        default:
          return customizedMonochrome;
      }
    },
  },
);
