import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import PALETTE from 'layouts/ThemeProvider/palette';

export const PILLARS = {
  environment: {
    slug: 'E',
    name: 'Environment',
  },
  social: {
    slug: 'S',
    name: 'Social',
  },
  governance: {
    slug: 'G',
    name: 'Governance',
  },
};

export const PILLARS_FILTER_LIST = [
  {
    key: 'e',
    name: 'Environment',
    gradientStartColor: PALETTE.mossGreen,
    gradientStopColor: PALETTE.deYork,
  },
  {
    key: 's',
    name: 'Social',
    gradientStartColor: PALETTE.macaroniAndCheese,
    gradientStopColor: PALETTE.saffronMango,
  },
  {
    key: 'g',
    name: 'Governance',
    gradientStartColor: PALETTE.viking,
    gradientStopColor: PALETTE.shakespeare,
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    displayOrder: PropTypes.number,
    slug: PropTypes.string,
    pillarId: PropTypes.number,
  },
  {},
);
