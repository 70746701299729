import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import FilterIcon from 'components/Icons/FilterIcon';
import SquareCheckbox from 'components/SquareCheckbox';

import useStyles from './useStyles';

const PillarsFilter = props => {
  const { selectedPillarFilter, pillarsFilterList, onSelectPillarFilter, onUnselectPillarFilter } = props;
  const classes = useStyles();
  const [isMenuOpen, setMenu] = useState(false);

  const handleMenuToggle = () => setMenu(prev => !prev);
  const handleMenuClose = () => setMenu(false);

  const isPillarSelected = pillar => {
    if (isEmpty(selectedPillarFilter)) {
      return false;
    }
    return selectedPillarFilter.key === pillar.key;
  };

  const handleChangeSelectedPillarFilter = pillar => () => {
    if (selectedPillarFilter.key === pillar.key) {
      onUnselectPillarFilter();
      return;
    }

    onSelectPillarFilter(pillar);
  };

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <div className={classes.root}>
        <Button
          classes={{ label: classes.filterLabel, root: classes.filterRoot }}
          className={classes.button}
          onClick={handleMenuToggle}
        >
          <span>Filter</span>
          <FilterIcon viewBox="0 0 19 14" classes={{ root: classes.filterIconRoot }} />
        </Button>
        {isMenuOpen && (
          <div className={classes.menu}>
            <div className={classes.title} onClick={handleMenuToggle} role="presentation">
              <span className={classes.name}>Filter</span>
              <FilterIcon viewBox="0 0 19 14" classes={{ root: classes.filterIconRoot }} />
            </div>
            <ul className={classes.list}>
              {pillarsFilterList.map(pillar => (
                <li key={pillar.key} className={classes.item}>
                  <SquareCheckbox
                    checked={isPillarSelected(pillar)}
                    onChange={handleChangeSelectedPillarFilter(pillar)}
                  />
                  <span className={classes.itemName}>
                    ({pillar.key.toUpperCase()}) {pillar.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

PillarsFilter.propTypes = {
  pillarsFilterList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectPillarFilter: PropTypes.func,
  onUnselectPillarFilter: PropTypes.func,
  selectedPillarFilter: PropTypes.shape(),
};

PillarsFilter.defaultProps = {
  selectedPillarFilter: null,
  onSelectPillarFilter: () => {},
  onUnselectPillarFilter: () => {},
};

export default PillarsFilter;
